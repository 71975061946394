import React, { useContext } from "react"
import { graphql } from "gatsby"
import ListItem from "../../components/ListItem"
import { titleIfy, slugify } from "../../utils/helpers"
import Seo from "../../components/seo"
import GImage from "../../components/GImage"
import StyledTitle from "../../components/StyledTitle"
import ContactForm from "../../components/formComponents/ContactForm"
import Footer from "../../components/Footer"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import BaseContext from "../../context/baseContext"
import styled from "@emotion/styled"

const InsetBoxShadow = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  box-shadow: 0px -20px 80px 50px rgba(0, 0, 0, 0.6) inset;
  -webkit-box-shadow: 0px -20px 80px 50px rgba(0, 0, 0, 0.6) inset;
  -moz-box-shadow: 0px -20px 80px 50px rgba(0, 0, 0, 0.6) inset;
`
const LesVins = ({ data: gqlData }) => {
  const headerRef = useContext(BaseContext)

  const params = {
    contactForm: {
      defaultSubject: "acheter",
      title: {
        text: "Contactez-nous",
        variant: "h3",
        component: "h3",
      },
    },
  }

  const { directus, bouchon } = gqlData
  return (
    <>
      <Seo
        title={`${directus.pages_settings.wines_title} - ${directus.pages_settings.wines_subtitle}`}
        description={`Japeloup propose des vins réalisés dans le respect des traditions et du terroir permettant à chaque millésime de s’exprimer pleinement. Les vendanges s’effectuent manuellement dès la pleine maturité des grappes, de fin août à début octobre, selon les cépages.`}
      />
      <section className="relative" ref={headerRef}>
        <InsetBoxShadow />
        <GImage
          objectPosition="center 20%"
          objectFit="cover"
          className="w-full h-80vh"
          imgClassName="w-full h-80vh"
          src={directus.pages_settings.wines_illustration.imageFile}
          alt={`Photo des vignes de la page des vins`}
        />
      </section>
      <div className="flex flex-col text-center items-center px-6 max-w-c_large mx-auto">
        <div className="pt-12 pb-12" id={slugify("Les Vins")}>
          <AnchorLink
            title={`Voir ${directus.pages_settings.wines_title.toLowerCase()}`}
            to={`/${slugify("Les Vins")}#${slugify("Les Vins")}`}
          >
            <h1 className="text-5xl font-bold">
              {titleIfy(directus.pages_settings.wines_title)}
            </h1>
          </AnchorLink>

          <h2 className="text-lg font-bold text-stone-400">
            {directus.pages_settings.wines_subtitle}
          </h2>
          {/* {directus.pages_settings.description.length > 8 && (
            <div
              className="text-stone-400"
              dangerouslySetInnerHTML={{
                __html: `${directus.pages_settings.description}`,
              }}
            />
          )} */}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 content-center">
          {directus.wines.map((item, index) => {
            return (
              <ListItem
                key={index}
                link={`/${slugify("Les Vins")}/${slugify(item.name)}`}
                title={item.name}
                order={index + 1}
                description={item.description}
                imageSrc={item.illustration && item.illustration.imageFile}
              />
            )
          })}
        </div>
      </div>

      <div>
        <div className="grid grid-cols-1 lg:grid-cols-6 gap-y-2 gap-x-6 px-8 lg:px-0 py-20 max-w-c_large mx-auto lg:flex-row">
          <div className="col-span-1 lg:col-span-3">
            <GImage src={bouchon} className="w-full object-cover" />
          </div>
          <div className="bg-stone-50 border-2 border-stone-100 col-span-1 lg:col-span-3 pt-20 pb-6 px-8 sm:px-20 relative">
            <StyledTitle
              text={params.contactForm.title.text}
              component="h3"
              className="h1-variant font-bold"
              darkTheme={false}
            />
            <ContactForm defaultSubject={params.contactForm.defaultSubject} />
          </div>
        </div>
      </div>
      {directus.pages_settings.mentions && (
        <Footer mentions={directus.pages_settings.mentions} />
      )}
    </>
  )
}

export default LesVins

export const pageQuery = graphql`
  query {
    # locales: allLocale(filter: { language: { eq: $language } }) {
    #   edges {
    #     node {
    #       ns
    #       data
    #       language
    #     }
    #   }
    # }
    directus: allDirectus {
      wines(sort: ["sort"]) {
        name
        description
        illustration(limit: 1) {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                width: 500
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#f3f3ed" }
              )
            }
          }
        }
      }
      pages_settings {
        wines_title
        wines_subtitle
        wines_illustration {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                quality: 80
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#f3f3ed" }
              )
            }
          }
        }
        mentions
      }
    }
    bouchon: file(relativePath: { eq: "les-vins/bouchon2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 80
          placeholder: TRACED_SVG
          tracedSVGOptions: { color: "#f3f3ed" }
        )
      }
    }
  }
`
