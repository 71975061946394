import React from "react"
import { Link } from "gatsby"
import GImage from "./GImage"

const ListItem = ({ link, title, imageSrc, description, order }) => (
  // ${
  //   order % 3 === 0 ? "md:col-span-2" : ""
  // }
  <div className={`md:w-11/12 mx-auto max-w-120 my-6 col-span-1 lg:col-span-1`}>
    <div className="bg-stone-50 border-2 border-stone-100 h-full">
      <Link to={`${link}`}>
        <div className="h-72 flex justify-center items-center">
          <div className="flex flex-column justify-center items-center w-full">
            <GImage
              alt={title}
              src={imageSrc}
              style={{ display: "block" }}
              className="w-full h-80 object-cover"
            />
          </div>
        </div>
      </Link>
      <div className="pt-2 px-6 pb-2">
        <h2 className="my-4 text-left font-bold py-3">
          <Link to={`${link}`}>{title}</Link>
        </h2>
        {/* <p className="text-center text-sm text-gray-700 mb-4">{`${price}`}</p> */}
        <div
          className="text-gray-500 text-justify"
          dangerouslySetInnerHTML={{ __html: `<div>${description}</div>` }}
        />
      </div>
    </div>
  </div>
)

export default ListItem
